export const USER_API = 'api/get-user';

export const VIEWER_GET_INFO_API = 'api/v2/design-cases';
export const EXPORT_PRODUCTS_API = 'api/v2/export-products';
export const PAID_EXPORT_PRODUCTS_API = 'api/v2/paid-export-product';

export const EXPORT_PRODUCTS_INVOICES_API = 'api/v2/export-products/invoices';
export const CREDIT_BALANCE_API = 'api/v2/credits/balance';

export const EXPORT_PAYMENT_QUEUE_API = 'api/v3/payment/payment-queue';

export const POST_CASE_REFRESH_TOKEN_ISSUANCE_API =
  'api/authenticate?grant_type=refresh_token';
export const DESIGN_CASE_VALIDATE_API =
  'api/v3/batch/design-cases/tooth-numbers/validate';
