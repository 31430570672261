import {
  BATCH_BUILD_PROTOCOL,
  BATCH_DEV_URL,
  BATCH_URL,
  CLOUD_URL,
  CROWN_URL
} from '../config';

export const ALLOW_ORIGIN_LIST = [
  CLOUD_URL,
  CROWN_URL,
  BATCH_URL,
  BATCH_DEV_URL,
  BATCH_BUILD_PROTOCOL
];
