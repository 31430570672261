import { Axios } from '.';
import { POST_CASE_REFRESH_TOKEN_ISSUANCE_API } from '../../Constants/Api/apiEndpoints';
import axios_default from 'axios';
import { BACKEND_BASE_URL } from '../../config';

const unAuthorizedInstance = axios_default.create({
  baseURL: BACKEND_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

export const requestAccessToken = async () => {
  let accessToken_ = null;
  try {
    const response = await unAuthorizedInstance.post(
      POST_CASE_REFRESH_TOKEN_ISSUANCE_API
    );
    const { accessToken } = response.data;
    if (typeof accessToken !== 'string') {
      console.error('accessToken is not string');
      return null;
    }
    accessToken_ = accessToken;
  } catch (err) {
    // Refresh Token 만료
    console.error(err, 'refresh token expired');
  }
  return accessToken_;
};

export const axios = Axios.createAxiosInstance({
  baseURL: BACKEND_BASE_URL,
  requestAccessToken: requestAccessToken
});
